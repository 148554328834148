import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { User } from "firebase/auth";

const dataState = createPersistedState();
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null as User | null,
    kml: null as Blob | null,
  },
  getters: {
    user: (state) => state.user,
    kml: (state) => state.kml,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setKml(state, kml) {
      state.kml = kml;
    },
  },
  actions: {},
  modules: {},
  plugins: [dataState],
});
