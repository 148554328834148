<template>
  <section
    class="section"
    style="
      height: 100vh;
      justify-content: center;
      align-items: center;
      display: flex;
    "
  >
    <div class="container" style="max-width: 50vw">
      <div id="login-box" class="card">
        <div class="card-content">
          <h1 class="title has-text-centered">Login</h1>
          <ValidationObserver v-slot="{ error }" @submit.prevent="login()">
            <form>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors, failed, passed }"
              >
                <b-field
                  label="Email"
                  :type="{ 'is-danger': failed, 'is-success': passed }"
                  :message="errors[0]"
                >
                  <b-input
                    type="email"
                    v-model="email"
                    maxlength="30"
                  ></b-input>
                </b-field>
              </ValidationProvider>
              <ValidationProvider
                name="password"
                rules="required"
                v-slot="{ errors, failed, passed }"
              >
                <b-field
                  label="Password"
                  :type="{ 'is-danger': failed, 'is-success': passed }"
                  :message="errors[0]"
                >
                  <b-input
                    type="password"
                    v-model="password"
                    password-reveal
                  ></b-input>
                </b-field>
              </ValidationProvider>
              <section class="section">
                <div class="content has-text-centered">
                  <button class="button is-primary">Login</button>
                </div>
              </section>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import store from "../store";

export default {
  name: "LoginView",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
    };
  },
  methods: {
    login() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          store.commit("setUser", user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    },
    handleSubmit() {
      console.log("submit");
    },
  },
};
</script>
