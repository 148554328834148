<template>
  <div class="map-page">
    <GmapMap
      ref="mapRef"
      id="map"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
    >
    </GmapMap>

    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
  </div>
</template>

<style>
#map {
  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;
}

.marker-position {
  padding-bottom: 60px;
  -webkit-text-stroke: 0.5px white;
}
</style>
<script>
import { getApp } from "firebase/app";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import { buildGPX, GarminBuilder } from "gpx-builder";
import { gpx } from "@tmcw/togeojson";
import tokml from "geojson-to-kml";
import {
  getFirestore,
  collection,
  onSnapshot,
  getDocs,
  QuerySnapshot,
  DocumentData,
  Unsubscribe,
  query,
  where,
  orderBy,
  GeoPoint,
} from "firebase/firestore";
import store from "@/store";

export default {
  name: "Track",
  data() {
    return {
      window: window,
      isLoading: false,
      unsubscribe: null,
      zoom: 16.5,
      center: {
        lat: 52.7234434,
        lng: -1.3786962,
      },
      directionsDisplay: null,
      directionsService: null,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: false,
        disableDefaultUi: true,
        mapId: "b4c8dccaab2202a3",
      },
      analytics: getAnalytics(),
      santaMarker: {
        position: {
          lat: 52.7234434,
          lng: -1.3786962,
        },
        label: {
          color: "#000000",
          fontWeight: "bold",
          fontSize: "16px",
          text: "Santa's Sleigh",
          className: "marker-position",
        },
      },
      userMarker: {
        position: {
          lat: 52.7234434,
          lng: -1.3786962,
        },
        label: {
          color: "#000000",
          fontWeight: "bold",
          fontSize: "16px",
          text: "Your Location",
          className: "marker-position",
        },
      },
    };
  },
  computed: {
    canGeolocate() {
      return !!navigator.geolocation;
    },
  },
  methods: {
    async loadKML() {
      let kml = null;

      const kmlData = await this.createKML();

      // Convert to blob
      const blob = new Blob([kmlData], { type: "text/xml" });

      // Upload to storage
      const storage = getStorage();
      const storageRef = ref(storage, "history/santa.kml");

      await uploadBytes(storageRef, blob);

      // Get download URL
      const url = await getDownloadURL(storageRef);

      const mapRef = this.$refs.mapRef;

      mapRef.$mapPromise.then(async (map) => {
        new this.window.google.maps.KmlLayer(url, {
          suppressInfoWindows: true,
          preserveViewport: false,
          map: map,
        });
      });
    },
    async createKML() {
      const { Point } = GarminBuilder.MODELS;

      const db = getFirestore();
      const historyRef = collection(db, "places", "tracker-1", "history");

      // order by timestamp from oldest to newest
      const q = query(historyRef, orderBy("time", "asc"));

      const docsSnap = await getDocs(q);

      let points = [];

      docsSnap.forEach((doc) => {
        const data = doc.data();

        const latitude = data.location.latitude;
        const longitude = data.location.longitude;

        const point = new Point(latitude, longitude, {
          time: data.time.toDate(),
        });

        points.push(point);
      });

      const gpxData = new GarminBuilder();

      gpxData.setSegmentPoints(points);

      const gpx = buildGPX(gpxData.toObject());

      // Convert GPX to KML
      const kml = this.gpx2kml(gpx);

      return kml;
    },
    async gpx2kml(gpxData) {
      // Conver the GPX to geojson
      const geojson = gpx(new DOMParser().parseFromString(gpxData, "text/xml"));

      // Convert the geojson to KML
      const kml = tokml(geojson);

      return kml;
    },
    async gpxToMap() {},
    async askLocation() {
      // Ask user for permission to use their location using buefy
      this.$buefy.dialog.confirm({
        title: "Use your location?",
        message:
          "We need your location to show it on the map, do we have permission to use it?",
        confirmText: "Yes",
        cancelText: "No",
        type: "is-info",
        hasIcon: true,
        icon: "location-arrow",
        iconPack: "fa",
        onConfirm: this.gettingLocation,
      });
    },
    async gettingLocation() {
      // Show toast
      this.$buefy.toast.open({
        message: "Getting your location...",
        type: "is-dark",
        duration: 3000,
        queue: true,
        position: "is-top",
        // actionText: null,
      });

      // Get the user's location
      await this.getLocation();

      // Show toast
      this.$buefy.toast.open({
        message: "Displaying your location!",
        type: "is-dark",
        duration: 3000,
        queue: true,
        position: "is-top",
        // actionText: null,
      });
    },
    async getLocation() {
      // Ask for location
      navigator.geolocation.watchPosition((res) => {
        const position = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };

        this.userMarker.position = position;
      });
    },
  },
  async mounted() {
    // Start loading
    this.isLoading = true;

    // Log page load event
    logEvent(this.analytics, "start_tracking", {
      page: "santa_tracker",
    });

    // Get Location
    if (navigator.geolocation) {
      await this.gettingLocation();
    } else {
      await this.askLocation();
    }

    // Get the markers
    // this.unsubscribe = await this.getMarkers();
    this.loadKML();
    // Load Routes
    // this.loadRoutes();

    // Stop loading
    this.isLoading = false;
  },
};
</script>
