import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";

Vue.config.productionTip = false;

import "./assets/style.scss";
Vue.use(Buefy);

// Initizalize Firebase
import config from "./config.json";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
initializeApp(config.firebase);
getAnalytics();

// Firebase Auth auto login
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.commit("setUser", user);

    // Redirect to home if logged in and page is not home
    if (router.currentRoute.name !== "home") {
      router.push("/");
    }
  } else {
    store.commit("setUser", null);

    // Redirect to login if not logged in and page is not login
    if (router.currentRoute.name !== "login") {
      router.push("/login");
    }
  }
});

// Initalize the Vue with Google Maps
// eslint-disable-next-line @typescript-eslint/no-var-requires
const VueGoogleMaps = require("vue2-google-maps");
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDBi2Jt0jMQiZiH3uhmTysTgxFMrmtKsEo",
    libraries: "places",
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
