import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";

import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginVIew.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];

// Redirect to login if not logged in
routes.forEach((route) => {
  if (route.meta?.requiresAuth) {
    route.beforeEnter = (to, from, next) => {
      if (store.getters.user) {
        next();
      } else {
        next("/login");
      }
    };
  }
});

// Redirect to home if logged in
routes.forEach((route) => {
  if (route.name === "login") {
    route.beforeEnter = (to, from, next) => {
      if (store.getters.user) {
        next("/");
      } else {
        next();
      }
    };
  }
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
